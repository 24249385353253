<template>
  <div class="order-print flex bg-f4f4f4">
    <!-- 右边部分 -->
    <div class="right">
      <!-- 列表 -->
      <div class="order-list">
        <div class="type-head">
          <div class="flex" style="width: 100%">
            <div
              v-for="i in tab_list"
              :key="i.id"
              @click="toogle(i)"
              :class="{ 'ac': tab_id == i.id }"
              class="ab"
            >
              {{ i.name }}
            </div>
          </div>
        </div>
        <div class="tab-content" v-if="tab_id == 1">
          <div class="tab-c-item">
            <div class="flex flex-ac flex-jsb mg-bt20">
              <div class="flex flex-ac ft-22">
                <van-icon name="info" color="#666" size="2.6rem" class="mg-rt10" />
                <span>会员等级说明：开启自动升级满足条件则自动升级，未开启则需手动调整。</span>
              </div>
              <button class="add-mem-cate" @click="openModal('add')" v-if="shop.user_model == 1">
                <van-icon name="add" size="2.6rem" class="mg-rt10" color="#fff" />
                <span>新增</span>
              </button>
            </div>
            <table
              width="100%"
              id="table"
              style="border-collapse: collapse"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <thead bgcolor="#F2F2F2">
                <tr
                  bgcolor="#F2F2F2"
                  class="thead-tr ft-24"
                  style="height: 9rem"
                >
                  <td
                    :class="[
                      'color-333',
                      'ft-24',
                      'text-center',
                      { width25: index == 4 },
                      { width15: index == 2 },
                    ]"
                    v-for="(item, index) in thead"
                    :key="index"
                    style="width: 17%"
                  >
                    {{ item }}
                  </td>
                </tr>
              </thead>
  
              <tbody v-if="contentList.length > 0" class="relative">
                <template v-for="(item, index) in contentList">
                  <tr
                    :class="['color-333', 'ft-24', 'text-center', 'tbody-tr', 'relative']"
                  >
                    <img v-if="item.is_def == 1" class="defaultimg" src="@/static/images/memcateDefault.png" />
                    <td style="width: 17%">{{ item.type_name }}</td>
                    <td style="width: 17%">{{ item.rate }}%</td>
                    <td class="width15">
                      <van-icon name="success" v-if="item.member_price == 1" />
                      <van-icon name="cross" v-else />
                    </td>
                    <td style="width: 17%">
                      <van-icon name="success" v-if="item.auto_rise == 1" />
                      <van-icon name="cross" v-else />
                    </td>
                    <td class="width15">
                      <template v-if="item.auto_rise == 1">累积{{item.type == 1 ? '消费' : '充值'}}满{{item.type_num}}元</template>
                    </td>
                    <td class="width15">{{ item.num }}</td>
                    <td align="center" class="width15">
                      <template v-if="shop.user_model == 1">
                        <van-icon
                          name="edit"
                          size="3.2rem"
                          @click.stop="openModal('updatememcate', item)"
                        />
                        <van-icon
                          name="delete-o"
                          color="#ee0a24"
                          size="3.2rem"
                          v-if="item.is_def != 1"
                          @click.stop="openDialog('delmemcate', item)"
                          class="mg-lt40"
                        />
                      </template>
                      <template v-else-if="shop.user_model == 0">无权操作</template>
                    </td>
                  </tr>
                  <tr v-if="index != contentList.length - 1" :class="['color-333', 'ft-24', 'text-center', 'tbody-tr', 'relative']">
                    <td colspan="4">
                      <img src="@/static/images/arrow-up.png" style="width: 5rem; height: 5rem;" />
                    </td>
                  </tr>
                </template>
              </tbody>
  
              <div class="mg-tp30 content" v-if="contentList.length === 0">
                <div class="nullStyle">
                  <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
                </div>
              </div>
            </table>
          </div>
        </div>
        <div class="tab-content" v-if="tab_id == 2">
          <div class="tab-c-item">
            <div class="card-title">积分获取</div>
            <div class="card-item-line">
              <div class="mg-rt60">
                <span class="mg-rt20" :class="{'color-c8c9cc': shop.user_model != 1}">是否启用</span>
              </div>
              <van-switch v-model="open_gain" active-color="#1890FF" size="3rem" :disabled="shop.user_model != 1" />
            </div>
            <div class="card-item-line">
              <div :class="{'color-c8c9cc': shop.user_model != 1}">
                <span>每消费</span>
                <!-- <input class="card-item-line-input" :disabled="shop.user_model != 1" @click="openNum('gain_threshold')" :value="gain_threshold" readonly /> -->
                <span>1</span>
                <span>元，获得</span>
                <input class="card-item-line-input" :disabled="shop.user_model != 1" @click="openNum('gain_num')" :value="gain_num" readonly />
                <span>积分</span>
              </div>
            </div>

            <div class="card-title mg-tp60">积分抵扣</div>
            <div class="card-item-line">
              <div class="mg-rt60">
                <span class="mg-rt20" :class="{'color-c8c9cc': shop.user_model != 1}">是否启用</span>
              </div>
              <van-switch v-model="open_deduction" active-color="#1890FF" size="3rem" :disabled="shop.user_model != 1" />
            </div>
            <div class="card-item-line">
              <div :class="{'color-c8c9cc': shop.user_model != 1}">
                <span>消耗</span>
                <!-- <input class="card-item-line-input" :disabled="shop.user_model != 1" @click="openNum('deduction_threshold')" :value="deduction_threshold" readonly /> -->
                <span>1</span>
                <span>积分，抵扣</span>
                <input class="card-item-line-input" :disabled="shop.user_model != 1" @click="openNum('deduction_num')" :value="deduction_num" readonly />
                <span>元</span>
              </div>
            </div>

            <div class="s-s-btns" v-if="shop.user_model == 1">
              <button class="s-s-btn bg-1588F5 color-fff" @click="saveIntegralRule()">保存</button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      @closePopDialog="closePopDialog"
      @confirmPopDialog="confirmPopDialog"
    ></popupOfDialog>

    <!-- 新增编辑弹窗  开始 -->
    <div class="add-modal" v-if="is_show_modal">
      <div class="modal-content-box relative" style="z-index: 2002;">
        <img src="@/static/images/close.png" class="close absolute" alt srcset @click="closeAddModal" />

        <div class="wrapper">
          <!-- 标题 -->
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">{{modalTitle}}</div>

          <div class="deskcontent">
            <div class="di-box">
              <div class="dilabel">名称</div>
              <input class="diinput" v-model="type_name"  />
            </div>
            <div class="di-box" style="margin-bottom: 4rem;">
              <div class="dilabel">折扣</div>
              <input class="diinput" v-model="rate" readonly @click="openNum('rate')" placeholder="输入1到100的整数，如90" />
              <span class="didanwei">%</span>
            </div>
            <div class="di-box" style="margin-bottom: 4rem;">
              <div class="dilabel">会员价</div>
              <van-switch v-model="is_mem_price" active-color="#1890FF" size="3rem" class="mg-lt20" />
              <div class="flex flex-ac ft-22">
                <van-icon name="info" color="#666" size="2.6rem" class="mg-lt10" />
                <span class="ft-22">开启后会员优先享受会员价，不与会员折扣同享</span>
              </div>
            </div>
            <div class="di-box" style="margin-bottom: 4rem;" v-if="!is_def">
              <div class="dilabel">自动升级</div>
              <van-switch v-model="is_auto_up" active-color="#1890FF" size="3rem" class="mg-lt20" />
            </div>
            <div class="di-box" style="align-items: baseline;" v-if="is_auto_up">
              <div class="dilabel">升级条件</div>
              <div class="mg-lt20">
                <van-radio-group v-model="condition" class="condition-box" @change="handleChange">
                  <van-radio class="condition-box-item" :name="1">
                    累计消费金额满
                    <input :disabled="condition == 2" readonly :value="condition_num" 
                      @click="openNum('condition_num')" class="condition_num-input" 
                      :class="{ 'c-n-i-d': condition == 2 }"
                    />
                    元
                  </van-radio>
                  <van-radio class="condition-box-item" :name="2">
                    累计充值金额满
                    <input :disabled="condition == 1" readonly :value="condition_num" 
                      @click="openNum('condition_num')" class="condition_num-input" 
                      :class="{ 'c-n-i-d': condition == 1 }"
                    />
                    元
                  </van-radio>
                </van-radio-group>
              </div>
            </div>
            <div class="di-box" style="margin-bottom: 4rem;">
              <div class="dilabel">设为默认</div>
              <van-switch v-model="is_def" active-color="#1890FF" size="3rem" class="mg-lt20" @change="is_auto_up = false;" />
            </div>
          </div>
        </div>
          <div style="height: 9rem;"></div>
        <!-- 确认/取消 -->
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeAddModal">取消</button>
          <button class="confirm " @click="confirmAddModal">确认</button>
        </div>
      </div>

    </div>
    <!-- 新增编辑弹窗  结束 -->
    <van-number-keyboard 
      v-model="numModel" 
      :title="numModel" 
      :show="isShowNumKeyboard" 
      @blur="isShowNumKeyboard = false;" 
      @input="handleNumChange" 
      @delete="handleNumDelete" 
      class="otherEatNum-nk" 
      :extra-key="extra_key"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import popupOfDialog from "../component/popupOfDialog.vue";
export default {
  components: {
    popupOfDialog,
  },
  data() {
    return {
      thead: ["会员等级", "会员折扣", "会员价", "自动升级", "升级条件", "人数", "操作"],
      contentList: [],
      openType: "add",
      modalTitle: "",
      type_name: "", // 分类名称
      rate: "", // 会员折扣
      is_mem_price: 0, // 会员价
      is_auto_up: 0, // 自动升级
      condition: 1, // 升级条件
      condition_num: '', // 升级条件数字
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
      diData: [],
      is_def: false, // 是否设为默认会员等级
      is_show_modal: false,
      cate_id: '',
      numModel: '',
			isShowNumKeyboard: false,
			numVals: [],
      numType: '',
      tab_list: [
        { id: 1, name: '会员等级' },
        { id: 2, name: '会员积分' },
      ],
      tab_id: 1,
      extra_key: '',
      gain_threshold: '1', // 消费多少元
      gain_num: '', // 获得多少积分
      deduction_threshold: '1', // 消耗多少积分
      deduction_num: '', // 抵扣多少元
      open_gain: false,
      open_deduction: false,
    };
  },
  computed: {
    ...mapGetters({ shop: "shop" }),
    role_type() {
      return localStorage.role_type
    }
  },
  methods: {
    getIntegtalSetting() {
      this.$api.shop_scoreGet().then(res => {
        if (res.code == 1) {
          if (res.data.score_set) {
            this.open_gain = res.data.score_set.is_open == 1;
            this.open_deduction = res.data.score_set.is_offset == 1;
            this.gain_num = res.data.score_set.open_score.toString();
            this.deduction_num = res.data.score_set.offset_score.toString();
          }
        } else {
          this.$toast(res.msg);
        }
      })
    },
    saveIntegralRule() {
      if (this.open_gain && !/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.gain_num)) {
        this.$toast('积分获取规则必须为数字，小数点后最多两位');
        return;
      }
      if (this.open_deduction && !/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.deduction_num)) {
        this.$toast('积分抵扣规则必须为数字，小数点后最多两位');
        return;
      }
      // console.log(this.gain_num, this.deduction_num);
      const score_set = {
        is_open: this.open_gain ? 1 : 0,
        open_score: this.gain_num,
        is_offset: this.open_deduction ? 1 : 0,
        offset_score: this.deduction_num,
      }
      let data = {
        score_set: score_set
      }
      this.$api.shop_scoreSet(data).then(res => {
        if (res.code == 1) {
          this.$toast("保存成功，门店设置已更新");
          this.getIntegtalSetting();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    toogle(i) {
      if (this.tab_id != i.id) {
        this.tab_id = i.id;
        if (this.tab_id == 1) {
          this.getData();
        } else if (this.tab_id == 2) {
          this.getIntegtalSetting();
        }
      }
    },
    openNum(type) {
      this.numType = type;
      this.extra_key = '';
			this.numModel = this.openType == 'add' ? '' : this[`${type}`];
      if (this.numType == 'gain_num' || this.numType == 'deduction_num') {
        this.extra_key = '.';
        this.numModel = this[`${type}`];
      }
      if (this.numType == 'gain_threshold' || this.numType == 'deduction_threshold') {
        this.numModel = this[`${type}`];
      }
      this.numVals = this.numModel.split('');
			this.isShowNumKeyboard = true;
		},
		handleNumChange(e) {
			this.numVals.push(e);
			this.numModel = this.numVals.join('');
			this[`${this.numType}`] = this.numModel;
		},
		handleNumDelete(e) {
			this.numVals.pop();
			this.numModel = this.numVals.length > 0 ? this.numVals.join('') : '';
			this[`${this.numType}`] = this.numModel;
		},
    handleChange() {
      this.condition_num = '';
    },
    confirmPopDialog(data) {
      switch (data.type) {
        case "delmemcate":
          if (data.data.is_def == 1) {
            this.$toast('默认会员等级不允许删除');
            return;
          }
          if (data.data.num > 0) {
            this.$toast('该会员等级下已有会员，不允许删除');
            return;
          }
          this.delCategory(data.data);
          this.closePopDialog();
          break;
      }
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog(type, item) {
      switch (type) {
        case "delmemcate":
          this.dialogTitle = "删除";
          this.dialogMsg = `确认删除此会员等级吗？`;
          this.dialogType = type;
          this.dialogData = item;
          this.showDialog = true;
          break;
      }
    },
    getData() {
      this.contentList = [];
      this.$api.getMemberTypeList().then((res) => {
        if (res.code == 1) {
          this.contentList = res.data.data || [];
        } else {
          this.$toast(res.msg);
          this.contentList = [];
        }
      });
    },
    // 打开弹窗
    async openModal(type, item) {
      this.openType = type;
      this.resetTemp();
      this.modalTitle = '新增会员等级';
      if (type == 'updatememcate') {
        this.modalTitle = '编辑会员等级';
        this.cate_id = item.id;
        this.is_def = item.is_def == 1;
        await this.getMemTypeInfo();
      }
      this.is_show_modal = true;
    },
    getMemTypeInfo() {
      return new Promise((resolve, reject) => {
        this.$api.getMemberTypeInfo({
          id: this.cate_id
        }).then(res => {
          if (res.code == 1) {
            const data = res.data.data || [];
            if (data.length > 0) {
              const info = data[0];
              this.type_name = info.type_name;
              this.rate = info.rate.toString();
              this.is_mem_price = info.member_price == 1;
              this.is_auto_up = info.auto_rise == 1;
              this.condition = Number(info.type);
              this.condition_num = info.type_num ? info.type_num.toString() : '';
              this.is_def = info.is_def == 1;
              resolve();
            }
          } else {
            this.$toast(res.msg);
          }
        })
      })
    },
    closeAddModal() {
      this.is_show_modal = false;
    },
    // 重置表单数据
    resetTemp() {
      this.id = "";
      this.type_name = "";
      this.rate = "";
      this.is_mem_price = 0;
      this.is_auto_up = 0;
      this.condition = 1;
      this.condition_num = '';
      this.is_def = false;
    },
    // 删除
    delCategory(item) {
      let data = {
        id: item.id,
      }
      this.$api.delMemberType(data).then(async (res) => {
        if (res.code == 1) {
          this.$toast("删除会员等级成功，门店设置已更新");
          this.getData();
        } else {
          this.$toast(res.msg || "该会员等级下已有会员，无法删除，请先删除会员");
        }
      });
    },
    // 提交表单
    confirmAddModal() {
      if (this.type_name == '') {
        this.$toast('会员等级名称不能为空')
        return
      }
      if (this.rate <= 0 || this.rate > 100 || !/^[0-9]*[1-9][0-9]*$/.test(this.rate)) {
        this.$toast('输入1到100的整数，如90')
        return
      }
      if (this.is_auto_up && (!this.condition_num || this.condition_num <= 0)) {
        this.$toast('请输入条件金额');
        return;
      }
      let data = {
        "type_name": this.type_name,
        "rate": this.rate,
        "member_price": this.is_mem_price ? 1 : 2, // 是否会员价： 1是 2否
        "auto_rise": this.is_auto_up ? 1 : 2, // 是否自动升级: 1是 2否
        "type": this.condition, // 条件: 1累计消费 2累计充值
        "type_num": this.condition_num, // 条件数值
        "sort": 1,
        "is_def": this.is_def ? 1 : 2,
      };
      if (this.openType == "updatememcate") {
        data['id'] = this.cate_id;
        this.$api.editMemberType(data).then(async (res) => {
          if (res.code == 1) {
            this.closeAddModal();
            this.$toast("编辑会员等级成功，门店设置已更新");
            this.getData();
          } else {
            this.$toast(res.msg || "编辑失败");
          }
        });
      } else {
        this.$api.addMemberType(data).then(async (res) => {
          if (res.code == 1) {
            this.closeAddModal();
            this.$toast("新增会员等级成功，门店设置已更新");
            this.getData();
          } else {
            this.$toast(res.msg || "新增失败");
          }
        });
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.s-s-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 10rem;
  border-top: 0.1rem solid #eeeeee;
  .s-s-btn {
    width: 43rem;
    height: 7rem;
    border-radius: 0.8rem;
    font-size: 2.4rem;
  }
}
.type-head {
  font-size: 2.6rem;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;

  .ab {
    // flex: 1;
    text-align: center;
    line-height: 8.5rem;
    height: 8.5rem;
    position: relative;
    font-weight: bold;
    margin-left: 2.5rem;
    width: 15%;
  }

  .ac::after {
    content: "";
    display: block;
    position: absolute;
    width: 12rem;
    height: 1.5rem;
    background: #1588F5;
    bottom: 0.5rem;
    left: calc(50% - 6rem);
    transform: translateY(-0.2rem);
    transition: 0.3s;
  }
}
.otherEatNum-nk {
	border-radius: 0.13rem;
	width: 30vw;
	left: 35vw;
	z-index: 9999;
}
.defaultimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 5.5rem;
  height: 5.5rem;
}
.order-print {
  height: 100%;
  overflow: hidden;
}
.right {
  background: #fff;
  width: 100%;
}

.width15 {
  width: 17%;
}
.width25 {
  width: 25%;
}
table thead {
  width: 100%;
  display: block;
  display: table;
  table-layout: fixed;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  vertical-align: middle;
}
table {
  background: white;
  display: table;
  // height: calc(100% - 8rem);
  // overflow-y: scroll;
  width: 100%;
  tbody {
    // overflow-y: scroll;
    display: block;
    // height: calc(100% - 9rem);
    width: 100%;
  }
  tr:nth-last-child(1) {
    margin-bottom: 2rem;
  }
  .tbody-tr {
    overflow-y: scroll;
    height: 9rem;
    width: 100%;
    border-bottom: 0.2rem rgba(0, 0, 0, 0.06) solid;
    &.odd {
      background: #fef7ef;
    }
    td {
      height: 9rem;
    }
  }
}

.fixed {
  position: fixed;
}

.order-list {
  flex: 1;
  height: 100%;
  // margin-left: 0.3rem;

  .add-mem-cate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14rem;
    height: 6rem;
    background: #1588f5;
    border-radius: 0.8rem;
    font-size: 2.4rem;
    color: #FFFFFF;
  }

  .tbody-tr {
    &.odd {
      background: #fef7ef;
    }
    td {
      height: 9rem;
    }
  }

  .tab-content {
    height: calc(100vh - 19rem);
    overflow-y: scroll;

    .tab-c-item {
      box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.5);
      // height: calc(100% - 10rem);
      border-radius: 0.8rem;
      margin: 2rem 3rem;
      padding: 3rem 3rem 2rem;
      text-align: left;
      .card-title {
        font-size: 2.6rem;
        font-weight: bold;
        padding-bottom: 3rem;
      }
      
      .card-item-line {
        padding: 3rem 1.5rem;
        border-bottom: 0.15rem solid #eee;
        display: flex;
        align-items: center;
        font-size: 2.4rem;
        .card-item-line-input {
          width: 10rem;
          height: 6.2rem;
          border: 0.2rem solid #DEDEDE;
          border-radius: 0.8rem;
          margin-left: 1rem;
          margin-right: 1rem;
          padding: 0 2rem;
          font-size: 2.4rem;
        }
      }
    }
  }
}

.nullStyle {
  padding-top: 2rem;
  text-align: center;
  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}

.add-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content-box {
		width: 90rem;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
		position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;
	
		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}
	}
	.btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}
  .deskcontent {
    width: 90%;
    font-size: 2.4rem;
    margin: 4rem 0 4rem;
    position: relative;
    min-height: 18vh;
    max-height: calc(88vh - 20rem);
    height: fit-content;
    overflow-y: scroll;

    .di-box {
      margin-bottom: 2.5rem;
      display: flex;
      align-items: center;
      .dilabel {
        font-size: 2.4rem;
        width: 11rem;
      }

      .diinput {
        width: 45rem;
        height: 6.2rem;
        border: 0.2rem solid #DEDEDE;
        border-radius: 0.8rem;
        margin-left: 2.4rem;
        padding: 0 2rem;
        font-size: 2.4rem;
        flex: 1;
      }

      .didanwei {
        margin-left: 3rem;
      }

      .condition-box {
        .condition-box-item:first-child {
          margin-bottom: 3rem;
        }

        .condition_num-input {
          width: 10rem;
          height: 6.2rem;
          border: 0.2rem solid #DEDEDE;
          border-radius: 0.8rem;
          margin-left: 1rem;
          margin-right: 1rem;
          padding: 0 2rem;
          font-size: 2.4rem;
        }

        .c-n-i-d {
          color: rgba(239, 239, 239, 0);
        }
      }
    }
  }
}
</style>
