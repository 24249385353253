<template>
  <div class="activity-temp">
    <div class="activity-box">
      <div class="activity-item" v-for="item in acticity_list" @click="jumpTo(item)">
        <img :src="item.bg" class="activity-bg" />
        <div class="ft-28 ft-bold mg-tp25">{{ item.title }}</div>
        <div class="ft-20 color-999 mg-tp15">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'activity',
  data() {
    return {
      acticity_list: [
        {
          title: '短信营销',
          description: '第一时间触达客户',
          bg: require('@/static/images/activity/a-1.png'),
          path: 'smsSet',
        },
        {
          title: '优惠券',
          description: '配置以及发放优惠券',
          bg: require('@/static/images/activity/a-2.png'),
          path: 'coupon-manage',
        },
        {
          title: '充值有礼',
          description: '开启会员充值活动',
          bg: require('@/static/images/activity/a-3.png'),
          path: 'recharge-scheme',
        },
        {
          title: '积分兑换',
          description: '积分兑换优惠券等礼品',
          bg: require('@/static/images/activity/a-4.png'),
          path: 'exchange-scheme',
        },
        // {
        //   title: '整单打折',
        //   description: '设置订单折扣或满减优惠',
        //   bg: require('@/static/images/activity/a-5.png'),
        // }
      ]
    }
  },
  methods: {
    jumpTo(item) {
      this.$router.push({
        name: item.path,
        query: {
          active: 13,
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
  .activity-temp {
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .activity-box {
    display: grid;
    grid-template-columns: repeat(4, 23.5%);
    grid-template-rows: repeat(auto-fill, 25rem);
    grid-gap: 2.5rem 2%;
    padding: 2rem 3rem;

    .activity-item {
      box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.5);
      border-radius: 0.8rem;
      background: #fff;
      height: 25rem;
      .activity-bg {
        width: 9rem;
        height: 9rem;
        background: #fff;
        margin-top: 3rem;
      }
    }
  }
</style>