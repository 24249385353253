<template>
  <div class="pagefe">
    <div class="pagexia">
      <!-- 左边选项卡 -->
      <div class="pageleft">
        <div v-for="menu in setting_menu" class="menu-item">
          <div
            class="option"
            @click="option(item, index)"
            v-for="(item, index) in menu"
            :class="item.value == active ? 'option_act' : ''"
          >
            <img :src="item.icon" class="menu-icon"
              :class="{
                'menu-icon-height-1': item.height == 1,
                'menu-icon-height-2': item.height == 2,
                'menu-icon-height-3': item.height == 3,
              }"
            />
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 右边区域 -->
      <div class="pageright">
        <!-- 门店信息 -->
        <div v-if="active == 1" style="height: 100%;">
          <store-setting></store-setting>
        </div>
        <!-- 会员等级 -->
        <div v-if="active == 2" style="height: 100%;">
          <member-category></member-category>
        </div>
        <!-- 员工管理 -->
        <div v-if="active == 3" style="height: 100%;">
          <employeeVue></employeeVue>
        </div>
        <!-- 打印设置 -->
        <div v-if="active == 4" style="height: 100%;">
          <set-print-type></set-print-type>
        </div>
        <!-- 短信设置 -->
        <div v-if="active == 8" style="height: 100%;">
          <sms-set></sms-set>
        </div>
        <!-- 管理模式 -->
        <template v-if="active == 5">
          <store-model></store-model>
        </template>
        <!-- 修改密码 -->
        <template v-if="active == 6">
          <store-paw></store-paw>
        </template>
        <!-- 库存管理 -->
        <template v-if="active == 9">
          <stock-manage></stock-manage>
        </template>
        <!-- 优惠券 -->
        <template v-if="active == 10">
          <coupon-manage></coupon-manage>
        </template>
        <!-- 关于我们 -->
        <template v-if="active == 12">
          <about-dzb></about-dzb>
        </template>
        <!-- 营销活动 -->
        <template v-if="active == 13">
          <activity></activity>
        </template>
      </div>
    </div>

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      @closePopDialog="closePopDialog"
      @confirmPopDialog="confirmPopDialog"
    ></popupOfDialog>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import system from "@/views/component/system";
// import printerTest from "./printerTest";
import employeeVue from '../home/employee.vue';
import memberCategory from "./member-category.vue";
import storeSetting from "./storeSetting.vue";
import smsSet from './smsSet.vue';
import storeModel from './storeModel.vue';
import storePaw from './storePaw.vue';
import setPrintType from './setPrintType.vue';
import { mapGetters } from 'vuex';
import stockManage from "./stock-manage.vue";
import couponManage from "./coupon-manage.vue";
import popupOfDialog from "@/views/component/popupOfDialog.vue";
import adoutDzb from "./about-dzb.vue";
import activity from "./activity.vue";

export default {
  // mixins: [printerTest],
  created() {
    this.setMenu();
    this.name = this.$route.query.name || 'featureSet'; //拿到传过来的标题
    this.active = this.$route.query.active || 1;
    console.log("name:", this.name);
  },
  components: {
    titlebar: titlebar,
    system: system,
    employeeVue,
    'member-category': memberCategory,
    'store-setting': storeSetting,
    'sms-set': smsSet,
    'store-model': storeModel,
    'store-paw': storePaw,
    'set-print-type': setPrintType,
    'stock-manage': stockManage,
    'coupon-manage': couponManage,
    popupOfDialog,
    'about-dzb': adoutDzb,
    'activity': activity,
  },
  data() {
    return {
      active: 1, // 选项卡下标
      lists: [
        [
          { name: '门店信息', value: 1, icon: require('@/static/images/setting/s1-store.png'), height: '1' },
          { name: '员工管理', value: 3, icon: require('@/static/images/setting/s3-satff.png'), height: '2' },
          { name: '会员设置', value: 2, icon: require('@/static/images/setting/s2-member.png'), height: '2' },
          // { name: '优惠券', value: 10, icon: require('@/static/images/setting/s10-coupon.png'), height: '1' },
          { name: '营销活动', value: 13, icon: require('@/static/images/setting/s10-coupon.png'), height: '1' },
          { name: '打印设置', value: 4, icon: require('@/static/images/setting/s4-print.png'), height: '1' },
          { name: '库存管理', value: 9, icon: require('@/static/images/setting/s9-stock.png'), height: '1' },
        ],
        // [
        //   { name: '短信设置', value: 8, icon: require('@/static/images/setting/s5-sms.png'),height: '2' },
        // ],
        // [
        //   { name: '关于我们', value: 12, icon: require('@/static/images/setting/s12-about.png'),height: '1' },
        // ],
        [
          { name: '管理模式', value: 5, icon: require('@/static/images/setting/s6-model.png'), height: '3' },
          { name: '修改密码', value: 6, icon: require('@/static/images/setting/s7-password.png'), height: '3' },
        ],
        [
          { name: '交接班', value: 7, type: 'jump', pageName: 'succession', icon: require('@/static/images/setting/s8-sus.png'), height: '3' }
        ],
        [
          { name: '退出登录', value: 11, type: 'logout', icon: require('@/static/images/setting/s11-logout.png'), height: '3' }
        ]
      ],
      setting_menu: [],
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
    };
  },
  computed: {
    ...mapGetters({shop: 'shop'}),
  },
  watch: {
    shop: {
      handler(newVal, oldVal) {
        this.setMenu();
      },
      deep: true
    },
  },
  methods: {
    setMenu() {
      let arr = [];
      for (let i = 0; i < this.lists.length; i++) {
        const element = this.lists[i];
        if ((this.shop.user_model != 1 && i == 2) || localStorage.user_type != 1) {
          let menu = [];
          for (let j = 0; j < element.length; j++) {
            const ele = element[j];
            if (ele.value != 6) {
              menu.push(ele);
            }
          }
          arr.push(menu)
        } else {
          arr.push(element);
        }
      }
      this.setting_menu = arr;
      this.$forceUpdate();
    },
    option(item, index) {
      if (item.type == 'jump') {
        this.$router.push({
          name: item.pageName,
          query: {
            name: item.pageName,
            active: this.active,
          }
        })
      } else if (item.type == 'logout') {
        this.dialogTitle = "退出登录";
        this.dialogMsg = `确认要退出登录吗？`;
        this.dialogType = '4';
        this.showDialog = true;
      } else {
        this.active = item.value;
        console.log("tab===", this.active);
        this.$router.push({ query: this.$route.query, params: {tab_id: 1} });
      }
    },
    closePopDialog() {
      this.showDialog = false;
    },
    confirmPopDialog() {
      this.quit();
      this.closePopDialog();
    },
    quit() {
      localStorage.removeItem("token");
      localStorage.removeItem("user_type");
      localStorage.removeItem("role_str");
      localStorage.removeItem('shop_only_code');
      this.$store.commit("updateShopCart", []);
      this.$store.commit("undateCurrentSwee_code", '');
      this.$router.replace({ name: "login" });
    },
  },
};
</script>

<style lang="less" scoped>
.pagefe {
  width: 100%;
  height: 100%;
  background: #f4f4f4;

  .pagexia {
    width: 100%;
    // height: 98rem;
    height: calc(100vh - 10.3rem);
    display: flex;

    // 左边区域
    .pageleft {
      width: 36rem;
      height: 98%;
      background: #f4f4f4;
      overflow-y: scroll;

      .menu-item {
        border-bottom: 1.5rem solid #f4f4f4;
      }

      .option {
        width: 100%;
        height: 8.4rem;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        font-size: 2.6rem;
        color: #333333;
        line-height: 3.7rem;

        .menu-icon {
          width: 3.6rem;
          margin-left: 3rem;
          margin-right: 3rem;
        }
      }
      .option_act {
        font-weight: bold;
        background: #e7f3ff;
      }
    }
    .menu-icon-height-1 {
      height: 3.5rem;
    }
    .menu-icon-height-2 {
      height: 3.2rem;
    }
    .menu-icon-height-3 {
      height: 4rem;
    }

    // 右边区域
    .pageright {
      width: calc(100% - 38rem);
      height: 100%;
      margin-left: 2rem;
    }
  }
}
</style>
