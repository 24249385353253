<template>
  <div class="page">
    <div class="type-head">
      <div class="flex" style="width: 100%">
        <div
          v-for="i in tab_list"
          :key="i.id"
          @click="toogle(i)"
          :class="{ 'ac': tab_id == i.id }"
          class="ab"
        >
          {{ i.name }}
        </div>
      </div>
    </div>
    <!-- 上方搜索栏,按钮 -->
    <div class="top_box" v-if="tab_id == 1 || tab_id == 2">
      <div class="top_input">
        <van-icon name="search" />
        <input type="text" placeholder="输入商品名称或条码" v-model="param" />
      </div>
      <button class="top_btn" @click="params.page = 1; getGoodsList()"> 搜索 </button>
      <div v-if="tab_id == 2">
        <button class="top_btn" @click="batchDel">确认</button>
        <button class="top_btn" @click="selectAll(1)">全选</button>
        <button type="default" class="top_btn" @click="selectAll(2)" style="background: #EAEAEA; color: #666666;">取消选中</button>
      </div>
    </div>
    <template v-if="tab_id == 1 || tab_id == 2">
      <div class="tab_list" :class="{ index_hight: categoryList.length > 8 }">
        <van-button v-for="(item, index) in categoryList" :key="index" class="tab_btn" :class="{ choose: categoryId == item.id }" @click="chooseClassify(item)">
          {{ item.name }}
        </van-button>
      </div>
      <div class="index_list">
        <div v-for="(item, i) in goodsList" :key="i" @click.stop="openModal(item, i)" class="index_box">
          <div>
            <div class="goodsName">{{ item.name }}</div>
            <div class="goods_code" style="">助记码：{{ item.code }}</div>
            <div class="goodsstock color-1588F5" v-if="item.pro_type == 1 && item.stock > 0">库存：{{ item.stock }}{{ item.dw }}</div>
            <div class="goodsstock color-e60012" v-if="item.pro_type == 1 && item.stock <= 0">库存：{{ item.stock }}{{ item.dw }}</div>
          </div>
          <van-icon v-if="tab_id == 1" name="edit" size="3.2rem" />
          <van-checkbox class="del-checkbox" icon-size="2.6rem" v-if="tab_id == 2" :value="item.delChoosed" shape="square" />
        </div>
      </div>
      <div style="width:100%;height:5rem;background:#fff">
        <van-pagination v-model="params.page" :total-items="total" :items-per-page="params.limit" @change="pageChange" force-ellipses />
      </div>
    </template>
    <div class="search-value" v-if="tab_id == 3 || tab_id == 4">
      <div class="flex flex-ac search-item">
        <div class="flex flex-jsc dimension">
          <div v-for="(item, index) in dimensionDate" :key="index" :class="[
            'dimensiondate',
            date_id == index ? 'dimensiondate-check' : '',
          ]" @click="chooseType(item, index)">
            <span> {{ item }}</span>
          </div>
        </div>
        <input v-model="start_time" class="ordernum-input search-date-input" readonly placeholder="选择时间"
          @click="showCalendarStart = true" style="margin: 0 0 0 2rem" />
        <span class="ft-24">&nbsp;&nbsp;至&nbsp;&nbsp;</span>
        <input v-model="end_time" class="ordernum-input search-date-input" placeholder="选择时间" readonly
          @click="showCalendarEnd = true" />
      </div>
      <van-popup v-model="showCalendarStart" round position="bottom" class="popup-picker-custom">
        <van-datetime-picker
          v-model="defaultDate[0]"
          :min-date="minDate"
          :max-date="maxDate"
          type="datetime"
          @confirm="onConfirmTime($event, 1)"
          @cancel="showCalendarStart = false"
          item-height="8.5rem"
          :formatter="formatter"
        />
      </van-popup>
      <van-popup v-model="showCalendarEnd" round position="bottom" class="popup-picker-custom">
        <van-datetime-picker
          v-model="defaultDate[1]"
          :min-date="minDate"
          :max-date="maxDate"
          type="datetime"
          @confirm="onConfirmTime($event, 2)"
          @cancel="showCalendarEnd = false"
          item-height="8.5rem"
          :formatter="formatter"
        />
      </van-popup>

      <button class="search-btn mg-rt20" @click="handleFifler">筛选</button>
      <button class="search-btn" @click="resetTemp">重置</button>
    </div>
    <template v-if="tab_id == 3">
      <div class="checklog-box">
        <div class="revenue-title flex flex-jsb">
          <div class="flex" style="align-items: center">
            <div class="ft-bold">时间：{{ start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{ end_time }}</div>
          </div>
        </div>

        <table width="100%" style="border-collapse: collapse; margin-top: 2.5rem" border="0" cellspacing="0"
          cellpadding="0">
          <thead>
            <tr bgcolor="#F2F2F2" class="thead-tr" style="height: 7.5rem;">
              <td class="color-333 ft-22 text-center ft-bold">盘点单号</td>
              <td class="color-333 ft-22 text-center ft-bold">盘点时间</td>
              <td class="color-333 ft-22 text-center ft-bold">盘点人</td>
              <td class="color-333 ft-22 text-center ft-bold">备注</td>
              <td class="color-333 ft-22 text-center ft-bold">操作</td>
            </tr>
          </thead>
          <tbody>
            <tr class="color-333 ft-22 text-center tbody-tr" v-for="(item, index) in check_log"
              :key="index">
              <td>{{ item.inventory_num }}</td>
              <td>{{ item.create_time }}</td>
              <td>{{ item.admin }}</td>
              <td>{{ item.mark }}</td>
              <td><span class="text-underline color-1588F5" @click="toDetail(item)">查看</span></td>
            </tr>
            <div class="mg-tp30 content" v-if="check_log.length === 0">
              <div class="nullStyle">
                <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
              </div>
            </div>
          </tbody>
        </table>
        <div class="flex mg-tp40" style="justify-content:flex-end;">
          <div class="pagination" v-if="count > 0">
            <van-pagination v-model="page" :total-items="count" :items-per-page="limit" mode="simple" force-ellipses
              @change="getCheckLog" style="background-color: #f2f2f2" />
          </div>
        </div>
      </div>
    </template>
    <template v-if="tab_id == 4">
      <div class="checklog-box">
        <div class="revenue-title flex flex-jsb">
          <div class="flex" style="align-items: center">
            <div class="ft-bold">时间：{{ start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{ end_time }}</div>
          </div>
        </div>

        <table width="100%" style="border-collapse: collapse; margin-top: 2.5rem" border="0" cellspacing="0"
          cellpadding="0">
          <thead>
            <tr bgcolor="#F2F2F2" class="thead-tr" style="height: 7.5rem;">
              <td class="color-333 ft-22 text-center ft-bold">时间</td>
              <td class="color-333 ft-22 text-center ft-bold">商品名称</td>
              <td class="color-333 ft-22 text-center ft-bold">变动类型</td>
              <td class="color-333 ft-22 text-center ft-bold">操作前库存</td>
              <td class="color-333 ft-22 text-center ft-bold">变动库存</td>
              <td class="color-333 ft-22 text-center ft-bold">操作后库存</td>
              <td class="color-333 ft-22 text-center ft-bold">操作人</td>
            </tr>
          </thead>
          <tbody>
            <tr class="color-333 ft-22 text-center tbody-tr" v-for="(item, index) in change_log"
              :key="index">
              <td style="word-break: break-word;">{{ item.create_time }}</td>
              <td>{{ item.name }}</td>
              <td>
                {{ item.change_type | ChangeType }}
                <van-icon v-if="item.change_type == 4 && item.content && item.content != ''"
                  style="vertical-align: text-top;" name="info" color="#666" size="3rem" class="mg-lt5"
                  @click="openDialog(item)"
                />
              </td>
              <td>{{ item.front }}</td>
              <td>{{ item.type == 1 ? '+' : '-' }}{{ item.num }}</td>
              <td>{{ item.after }}</td>
              <td>{{ item.admin_name }}</td>
            </tr>
            <div class="mg-tp30 content" v-if="change_log.length === 0">
              <div class="nullStyle">
                <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
              </div>
            </div>
          </tbody>
        </table>
        <div class="flex mg-tp40" style="justify-content:flex-end;">
          <div class="pagination" v-if="count > 0">
            <van-pagination v-model="page" :total-items="count" :items-per-page="limit" mode="simple" force-ellipses
              @change="handleChangePage" style="background-color: #f2f2f2" />
          </div>
        </div>
      </div>
    </template>

    <!-- 调整库存弹窗 开始 -->
    <div class="change-stock-modal" v-if="is_show_change_modal">
      <div class="modal-content-box relative" style="z-index: 2002;">
        <img src="@/static/images/close.png" class="close absolute" alt srcset @click="closeModal" />

        <div class="wrapper">
          <!-- 标题 -->
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">调整库存</div>

          <div class="mocontent">
            <div class="deskcontent">
              <div class="di-box" style="margin-top: 0;">
                <div class="dilabel required-tag">类型：<span style="color: #fff; visibility: hidden;">类型</span></div>
                <van-radio-group v-model="change_type" direction="horizontal">
                  <van-radio :name="1" icon-size="3rem">入库</van-radio>
                  <van-radio :name="2" icon-size="3rem" v-if="shop.user_model == 1">出库</van-radio>
                </van-radio-group>
              </div>
              <div class="di-box flex-jsb">
                <div class="dilabel required-tag">变动数量：</div>
                <input v-if="platform_name == 'android'" class="diinput" v-model="change_num" placeholder="只允许输入大于0的正整数" readonly @click="openKeyboard('change_num')" />
                <input v-else class="diinput" v-model="change_num" placeholder="只允许输入大于0的正整数" type="number" :min="1" />
              </div>
              <div class="di-box flex-jsb">
                <div class="dilabel">备注：<span style="color: #fff; visibility: hidden;">备注</span></div>
                <textarea v-model="change_remark" class="ditextarea" maxlength="50"></textarea>
              </div>
            </div>
          </div>
        </div>
          <div style="height: 9rem;"></div>
        <!-- 确认/取消 -->
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeModal">取消</button>
          <button class="confirm " @click="confirmModal()">确认</button>
        </div>
      </div>
    </div>
    <!-- 调整库存弹窗 结束 -->

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      :showCancelBtn="false"
      :showConfirmBtn="false"
      @closePopDialog="closePopDialog"
    ></popupOfDialog>

    <van-number-keyboard
      v-model="numVal"
      :title="numVal"
      :show="show_keyboard"
      extra-key="."
      @blur="show_keyboard = false;"
      @input="handleNumChange"
      @delete="handleNumDelete"
      class="otherEatNum-nk"
    />
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { Toast } from "vant"
import { mapGetters } from 'vuex';
import { datetimePickerFormatter, formatTimeWitchLine, getBeforeDate } from '../../utils/util';
import popupOfDialog from '@/views/component/popupOfDialog.vue';

export default {
  components: {
    titlebar: titlebar,
    popupOfDialog,
  },
  data() {
    return {
      chooseIndex: "", //选中的分类
      goodsList: [], // 商品列表
      categoryList: [], // 商品分类列表
      categoryId: "", // 选择的商品分类ID
      param: "", // 商品名称或条码
      batchDelFlag: false, // 批量删除flag
      lastCategoryId: undefined, // 从商品详情回来储存的分类ID
      params: {
        page: 1,
        limit: 20,
      },
      total: 0,
      tab_list: [
        { id: 1, name: '库存调整' },
        { id: 4, name: '调整记录' },
        { id: 2, name: '库存盘点' },
        { id: 3, name: '盘点记录' },
      ],
      tab_id: 1,
      is_show_change_modal: false,
      change_food: {},
      change_food_index: -1,
      change_type: 1, // 1增 2减
      change_num: '',
      change_remark: '',
      dimensionDate: ["今天", "昨天", "7天", "30天"],
      date_id: 0,
      start_time: "",
      end_time: "",
      page: 1, // 当前页数
      limit: 6,
      count: 0,
      defaultDate: [],
      minDate: new Date(),
      maxDate: new Date(),
      showCalendarStart: false,
      showCalendarEnd: false,
      formatter: datetimePickerFormatter,
      change_log: [],
      check_log: [], // 盘点记录
      showDialog: false,
      dialogType: '',
      dialogTitle: '',
      dialogMsg: '',
      dialogData: {},
      numberModel: '', // 页面数据参数
      numVal: '', // 数字键盘显示金额
      numValArr: [], // 数字键盘金额数组
      show_keyboard: false,
      platform_name: localStorage.nowDevice || '',
    };
  },
  computed: {
    ...mapGetters({shop: 'shop'}),
    stock_check_list() {
      return this.$store.state.stock_check_list;
    },
  },
  filters: {
    // 变动类型：1、初始库存 2、销售单 3、退货 4、手动调整 5、盘点
    ChangeType(val) {
      let obj = {
        1: '初始库存',
        2: '销售单',
        3: '退货',
        4: '手动调整',
        5: '盘点',
      }
      return obj[val] || '';
    },
  },
  async created() {
    console.log(this.$route.params);
    this.$store.commit('updateStockCheckList', []);
    this.tab_id = this.$route.params.tab_id || 1;
    this.getCurTabData();
    let newD = new Date().setMonth(new Date().getMonth() - 2)
    newD = new Date(newD).setHours(0);
    newD = new Date(newD).setMinutes(0);
    this.minDate = new Date(newD);
    this.maxDate = new Date();
  },
  methods: {
    handleNumChange(e) {
			this.numValArr.push(e);
			this.numVal = this.numValArr.join('');
			this[`${this.numberModel}`] = this.numVal;
		},
		handleNumDelete(e) {
			this.numValArr.pop();
			this.numVal = this.numValArr.length > 0 ? this.numValArr.join('') : '';
			this[`${this.numberModel}`] = this.numVal;
		},
    openKeyboard(name) {
      console.log(this[`${name}`]);
      this.numberModel = name;
      this.numVal = this[`${name}`] || '';
      this.numValArr = this[`${name}`].split('');
      this.show_keyboard = true;
    },
    handleChangePage() {
      if (this.tab_id == 3) {
        this.getCheckLog();
      } else if (this.tab_id == 4) {
        this.getChangeLog();
      }
    },
    closePopDialog() {
      this.showDialog = false;
    },
    openDialog(item) {
      this.dialogType = '4';
      this.dialogTitle = '备注';
      this.dialogMsg = item.content;
      this.showDialog = true;
    },
    toDetail(item) {
      this.$router.push({
        name: 'stockCheckDetail',
        query: {
          active: 9,
          tab_id: this.tab_id,
          log_id: item.id,
        }
      });
    },
    getChangeLog() {
      this.$api.ShopStockLog_getList({
        page: this.page,
        limit: this.limit,
        start_time: this.start_time,
        end_time: this.end_time,
      }).then(res => {
        if (res.code == 1) {
          this.change_log = res.data.list || [];
          this.count = res.data.count || 0;
        } else {
          this.change_log = [];
        }
      })
    },
    handleFifler() {
      if (this.start_time == "" && this.end_time != "") {
        this.$toast("请选择开始日期");
        return;
      }
      if (this.start_time != "" && this.end_time == "") {
        this.$toast("请选择结束日期");
        return;
      }
      this.page = 1;
      if (this.tab_id == 3) {
        this.getCheckLog();
      } else if (this.tab_id == 4) {
        this.getChangeLog();
      }
    },
    resetTemp() {
      this.start_time = '';
      this.end_time = '';
      this.date_id = 0;
      this.getselectTime();
      if (this.tab_id == 3) {
        this.getCheckLog();
      } else if (this.tab_id == 4) {
        this.getChangeLog();
      }
    },
    onConfirmTime(event, type) {
      // console.log(event, type);
      if (type == 1) {
        if (
          this.endDateTime > 0 &&
          new Date(event).getTime() > this.endDateTime
        ) {
          this.$toast("开始日期不能大于结束日期");
          return;
        }
        this.startDateTime = new Date(event).getTime();
        this.start_time = formatTime(event, 'yyyy-MM-dd hh:mm');
        this.showCalendarStart = false;
      } else {
        if (
          this.startDateTime > 0 &&
          new Date(event).getTime() < this.startDateTime
        ) {
          this.$toast("结束日期不能小于开始日期");
          return;
        }
        this.endDateTime = new Date(event).getTime();
        this.end_time = formatTime(event, 'yyyy-MM-dd hh:mm');
        this.showCalendarEnd = false;
      }
    },
    getCheckLog() {
      this.$api.inventoryLog_getList({
        page: this.page,
        limit: this.limit,
        start_time: this.start_time,
        end_time: this.end_time,
      }).then(res => {
        if (res.code == 1) {
          this.check_log = res.data.list || [];
          this.count = res.data.count || 0;
        } else {
          this.check_log = [];
        }
      })
    },
    // 选择时间
    getselectTime() {
      switch (this.date_id) {
        case 0:
          this.start_time = formatTimeWitchLine(new Date()) + ' 00:00';
          this.end_time = formatTimeWitchLine(new Date()) + ' 23:59';
          this.defaultDate = [new Date(), new Date()];
          break;
        case 1:
          let beforeDate = getBeforeDate(1);
          this.start_time = formatTimeWitchLine(beforeDate) + ' 00:00';
          this.end_time = formatTimeWitchLine(beforeDate) + ' 23:59';
          this.defaultDate = [beforeDate, new Date()];
          break;
        case 2:
          let beforeDate_1 = getBeforeDate(6);
          this.start_time = formatTimeWitchLine(beforeDate_1) + ' 00:00';
          this.end_time = formatTimeWitchLine(new Date()) + ' 23:59';
          this.defaultDate = [beforeDate_1, new Date()];
          break;
        case 3:
          let beforeDate_2 = getBeforeDate(29);
          this.start_time = formatTimeWitchLine(beforeDate_2) + ' 00:00';
          this.end_time = formatTimeWitchLine(new Date()) + ' 23:59';
          this.defaultDate = [beforeDate_2, new Date()];
          break;
        default:
          break;
      }
    },
    chooseType(item, index) {
      if (this.date_id != index) {
        this.date_id = index;
        this.getselectTime();
        if (this.tab_id == 3) {
          this.getCheckLog();
        } else if (this.tab_id == 4) {
          this.getChangeLog();
        }
      }
    },
    setSelectGood() {
      this.goodsList.forEach(element => {
        const index = this.stock_check_list.findIndex(e => e.id == element.id);
        if (index > -1) {
          this.$set(element, 'delChoosed', true);
        } else {
          this.$set(element, 'delChoosed', false);
        }
      });
    },
    updateFoodStock() {
      return new Promise((resolve, reject) => {
        let query = {
          type: this.change_type, // 1入库 2出库
          pid: this.change_food.id,
          content: this.change_remark,
          num: this.change_num,
        }
        this.$api.ShopStockLog_addStock(query).then(res => {
          this.$toast(res.msg);
          if (res.code == 1) {
            resolve();
          } else {
            this.$toast(res.msg);
            reject();
          }
        })
      })
    },
    async confirmModal() {
      if (this.change_num == '') {
        this.$toast('请输入变动数量');
        return;
      }
      if (Number(this.change_num) <= 0) {
        this.$toast('变动数量不可为0');
        return;
      }
      if (this.change_food.dw != '克' && this.change_food.dw != '千克' && this.change_food.dw != '斤' && !/^[0-9]*[1-9][0-9]*$/.test(this.change_num)) {
        this.$toast('变动数量必须是整数');
        return;
      }
      if (this.change_type == 2 && Number(this.change_num) > Number(this.change_food.stock)) {
        this.$toast('库存不足');
        return;
      }
      await this.updateFoodStock();
      this.getGoodsList();
      this.closeModal();
    },
    closeModal() {
      this.is_show_change_modal = false;
      this.change_food = {};
      this.change_food_index = -1;
      this.change_type = 1;
      this.change_num = '';
    },
    openModal(item, index) {
      if (this.tab_id == 1) {
        this.change_food = item;
        this.change_food_index = index;
        this.is_show_change_modal = true;
      } else if (this.tab_id == 2) {
        item.delChoosed ? item.delChoosed = false : this.$set(item, 'delChoosed', true);
        let arr = this.stock_check_list;
        if (item.delChoosed) {
          arr.push(item);
        } else {
          const index = arr.findIndex(e => e.id == item.id);
          arr.splice(index, 1);
        }
        this.$store.commit('updateStockCheckList', arr);
      }
    },
    toogle(i) {
      if (this.tab_id != i.id) {
        this.tab_id = i.id;
        this.getCurTabData();
      }
    },
    async getCurTabData() {
      if (this.tab_id == 1) {
        await this.getCategoryList();
        this.getGoodsList();
      } else if (this.tab_id == 2) {
        await this.getCategoryList();
        await this.getGoodsList();
        this.setSelectGood();
      } else if (this.tab_id == 3) {
        this.page = 1;
        this.getselectTime();
        this.getCheckLog();
      } else if (this.tab_id == 4) {
        this.page = 1;
        this.getselectTime();
        this.getChangeLog();
      }
    },
    async pageChange() {
      await this.getGoodsList();
      if (this.tab_id == 2) {
        this.setSelectGood();
      }
    },
    // 拉取商品分类列表
    getCategoryList() {
      return new Promise((resolve, reject) => {
        this.$api
          .goodsType({
            shop_id: localStorage.getItem("shop_id"),
          })
          .then((res) => {
            if (res.code == 1) {
              this.categoryList = res.data.list || [];
              if (this.lastCategoryId) {
                this.categoryId = this.lastCategoryId
              } else {
                this.categoryId = res.data.list.length > 0 ? res.data.list[0].id : "";
              }
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            console.log("首页拉取商品分类 error：", error);
            reject();
          });
      });
    },
    // 拉取商品列表
    getGoodsList() {
      return new Promise((resolve, reject) => {
        let query = {
          // shop_id: localStorage.getItem('shop_id'), // 门店ID
          shop_id: localStorage.getItem("shop_id"), // 门店ID
          c_id: this.categoryId, // 分类ID -1获取平台全部商品 0没有分类id则是获取全部门店商品 大于0是获取指定分类下面的商品
          // c_id: -1,
          param: this.param,
          page: this.params.page,
          limit: this.params.limit,
          pro_type: 1,
        };
        this.$api.goodsList(query).then((res) => {
          this.goodsList = res.data.list;
          this.total = res.data.count;
          resolve();
        });
      })
    },
    // 切换分类
    async chooseClassify(item) {
      this.batchDelFlag = false
      this.categoryId = item.id;
      await this.getGoodsList();
      if (this.tab_id == 2) {
        this.setSelectGood();
      }
    },
    batchDel() {
      console.log('this.stock_check_list', this.stock_check_list);
      if (!this.stock_check_list.length) return Toast('请选择要盘点的商品');
      this.$router.push({
        name: 'stockCheck',
        query: {
          active: 9,
          tab_id: this.tab_id,
        }
      });
    },
    // 全选
    selectAll(type) {
      let arr = this.stock_check_list;
      this.goodsList.forEach(item => {
        this.$set(item, 'delChoosed', type == 1);
        if (type == 1) {
          arr.push(item);
        } else {
          const index = arr.findIndex(e => e.id == item.id);
          arr.splice(index, 1);
        }
      })
      this.$store.commit('updateStockCheckList', arr);
    }
  },
  destroyed() {
    console.log('90099990');
    // this.$router.relpace({ query: this.$route.query, params: {tab_id: 1} });
  },
  watch: {
    tab_id(n) {
      if (n != 2) {
        this.goodsList.forEach(item => {
          if (item.delChoosed) item.delChoosed = false
        })
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.query.categoryId) {
        vm._data.lastCategoryId = from.query.categoryId
      }
    })
  }
};
</script>

<style lang="less" scoped>
.otherEatNum-nk {
  border-radius: 0.13rem;
  width: 30vw;
  left: 35vw;
  z-index: 9999;
}
.nullStyle {
  padding-top: 2rem;
  text-align: center;

  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}
.checklog-box {
  margin: 1rem 2rem;
  .revenue-title {
    font-size: 2.6rem;
    color: #333333;
  }
  .pagination {
    width: 30%;
    /deep/.van-pagination {
      font-size: 1.9rem;
    }
    /deep/.van-pagination__item--active {
      background-color: #1588F5;
    }
  }
  table thead,
  tbody tr,
  tfoot tr {
    display: table;
    width: 100%;
    table-layout: fixed;

    td {
      word-break: break-all;
    }
  }

  table {
    background: white;
    position: relative;
    display: table;
    // height: 100%;
    // overflow-y: scroll;
    width: 100%;

    ::-webkit-scrollbar {
      display: block;
      width: 0.6rem;
      height: 0.5rem;
      background-color: #fff;
    }

    ::-webkit-scrollbar-thumb {
      display: block;
      min-height: 0.5rem;
      min-width: 0.4rem;
      border-radius: 0.8rem;
      background-color: rgb(217, 217, 217);
    }

    thead {
      width: calc(100% - 0.7rem);
    }

    thead td {
      border: 0.15rem rgba(0, 0, 0, 0.06) solid;
    }

    tbody {
      overflow-y: scroll;
      display: block;
      height: calc(85vh - 38rem);
      width: 100%;
      overflow-x: hidden;
      position: relative;
    }

    .tbody-tr {
      overflow-y: scroll;
      height: 7.5rem;
      width: 100%;

      &.odd {
        background: #fef7ef;
      }

      td {
        border: 0.15rem rgba(0, 0, 0, 0.06) solid;
      }
    }
  }
}
.search-value {
  display: flex;
  flex-wrap: wrap;
  // background: #fff;
  padding: 0 3rem 0 0;
  margin: 2rem 2rem;
  align-items: center;

  .search-item {
    margin: 0 3rem 0 0;

    .timestyle {
      font-weight: 400;
      font-size: 2.4rem;
      color: #666666;
      padding-right: 2rem;
    }

    .dimension {
      border-radius: 0.8rem;
      font-weight: 400;
      font-size: 2.2rem;
      color: #666666;

      .dimensiondate {
        width: 7.8rem;
        height: 4.8rem;
        background: #ffffff;
        border: 0.1rem solid #dedede;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -0.2rem;
      }

      .dimensiondate:first-child {
        border-radius: 0.8rem 0 0 0.8rem;
      }
      .dimensiondate:last-child {
        border-radius: 0 0.8rem 0.8rem 0;
      }

      .dimensiondate-check {
        background: #1588F5;
        border-color: #1588F5;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -0.2rem;
        color: #fff;
      }
    }
  }

  .ordernum-input {
    border: 0.1rem #DEDEDE solid;
    width: 21.5rem;
    height: 4.8rem;
    border-radius: 0.8rem;
    font-size: 2.2rem;
    padding-left: 1.5rem;
  }

  .search-btn {
    background: #1588F5;
    font-size: 2.4rem;
    color: #fff;
    height: 4.8rem;
    padding: 0 2.5rem;
    border-radius: 0.8rem;
  }

  .search-date-input {
    width: 21.5rem;
    font-size: 2.4rem;
    // text-align: center;
  }
}
.change-stock-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

	.modal-content-box {
		width: 82rem;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
		position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;

		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}

    .mocontent {
      margin: 4rem 0 4rem;
      position: relative;
      display: flex;
      justify-content: center;
      overflow-y: scroll;
      padding: 0 5rem;
      .deskcontent {
        // display: flex;
        // align-items: flex-start;
        font-size: 2.4rem;

        .di-box {
          margin-top: 3rem;
          display: flex;
          align-items: center;
          .dilabel {
            font-size: 2.4rem;
          }

          .required-tag {
            position: relative;
          }
          .required-tag::after {
            position: absolute;
            left: -1.5rem;
            color: #ee0a24;
            font-size: 2.6rem;
            content: "*";
            top: 0.5rem;
          }

          .diinput {
            width: 40rem;
            height: 6rem;
            border: 0.15rem solid #DEDEDE;
            border-radius: 0.8rem;
            padding: 0 2.5rem;
            flex: 1;
            font-size: 2.4rem;
          }

          .ditextarea {
            width: 40rem;
            height: 10rem;
            border: 0.15rem solid #DEDEDE;
            border-radius: 0.8rem;
            padding: 2.5rem;
            flex: 1;
          }
        }
      }
    }
	}
	.btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}
}
.type-head {
  font-size: 2.6rem;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;

  .ab {
    // flex: 1;
    text-align: center;
    line-height: 8.5rem;
    height: 8.5rem;
    position: relative;
    font-weight: bold;
    margin-left: 2.5rem;
    width: 15%;
  }

  .ac::after {
    content: "";
    display: block;
    position: absolute;
    width: 12rem;
    height: 1.5rem;
    background: #1588F5;
    bottom: 0.5rem;
    left: calc(50% - 6rem);
    transform: translateY(-0.2rem);
    transition: 0.3s;
  }
}
.page {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;

  .top_box {
    padding: 2rem;
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .top_input {
      width: 45rem;
      height: 6.5rem;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      font-size: 2.4rem;
      font-weight: 400;
      color: #999999;
      border-radius: 0.8rem;
      padding-left: 2.5rem;

      input {
        background-color: #f5f5f5;
        margin-left: 2rem;
      }
    }

    .top_btn {
      padding: 0 2rem;
      height: 6rem;
      background: #1588f5;
      font-size: 2.3rem;
      color: #ffffff;
      margin-left: 2rem;
      border-radius: 0.8rem;
    }
  }

  .tab_list {
    margin: 0 2rem;
    width: calc(100% - 4rem);
    // background-color: #1588f5;
    display: grid;
    grid-template-columns: repeat(8, 12.5%);
    height: 8rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab_btn {
      height: 7.5rem;
      background-color: #F4F4F4;
      border: none;
      border-radius: 0.8rem;
      font-size: 2.6rem;
      font-weight: 500;
      color: #333;
      line-height: 3.7rem;
      margin-right: 1.5rem;
      margin-bottom: 1rem;
    }

    .choose {
      background-color: #1588f5;
      color: #fff;
    }
  }

  .index_hight {
    height: 16rem !important;
  }

  .index_list {
    width: calc(100% - 4rem);
    // height: 50rem;
    flex: 1;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
    display: grid;
    grid-template-columns: repeat(6, 15.9%);
    grid-template-rows: repeat(auto-fill, 12rem);
    grid-gap: 1.5rem 1.3rem;
    overflow-x: hidden;
    overflow-y: scroll;

    .addpro-index-box {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.8rem;
      color: #1588F5;
      font-weight: bold;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    .index_box {
      position: relative;
      background: #ffffff;
      box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      padding: 6.5rem 1.5rem;

      .goodsName {
        font-size: 1.5rem;
        font-weight: bold;
        color: #333333;
        line-height: 2.2rem;
        margin-bottom: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
      .goodsstock {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .goods_code{
        word-break: break-all;
        margin-bottom: 1rem;
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 1.5rem;
      }
      .del-checkbox {
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
      }
    }

    .isadd-icon {
      background: rgba(245, 176, 135, 0.2) !important;
    }

    .addGoods {
      width: 31.9rem;
      height: 12rem;
      background: #ffffff;
      box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
      margin-bottom: 1.9rem;
      text-align: center;
      font-size: 2.6rem;
      font-weight: 400;
      color: #333333;
      line-height: 12rem;
    }

    .ft_30 {
      font-size: 3rem;
    }
  }
}
</style>
